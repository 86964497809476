<template>
  <v-layout>
    <SystemsettingsOverviewAppBar />

    <v-container class="px-1">
      <v-row align="center" justify="center">
        <v-col cols="12" xl="4" lg="4" md="6" sm="6" v-for="item in items" :key="item.title">
          <v-card>
            <v-card-title class="text-no-wrap" color="primary">
              <span class="mx-auto my-3 text-h2 primary--text">
                {{ item.amount }}
              </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text--primary py-0">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }} </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon outlined :to="item.to"><v-icon>mdi-pencil</v-icon> </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import SystemsettingsOverviewAppBar from "../../components/appbars/SystemsettingsOverviewAppBar.vue";

export default {
  components: {
    SystemsettingsOverviewAppBar,
  },

  // created() {
  //   this.initialize();
  // },

  mounted() {
    this.initialize();
  },

  data() {
    return {
      first: null,
      items: [
        {
          title: "Mitarbeiter",
          amount: null,
          to: "/wws/settings/system/coworkers",
        },
      ],
    };
  },

  computed: {},

  methods: {
    async initialize() {
      const response = await fetch(`/api/settings/systemsettings`);
      const json = await response.json();
      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.items[0].amount = this.first.Mitarbeiter;
    },
  },
};
</script>
